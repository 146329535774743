import React, { useEffect, useState } from 'react';
import axios from 'axios';

import '../assets/css/booking.css';

import { useAuthContext } from '../hooks/useAuthContext';
import { useBooking } from '../hooks/useBooking';

import RedJet from '../assets/img/jet-img/red.jpg';
import PinkJet from '../assets/img/jet-img/pink.jpg';
import OrangeJet from '../assets/img/jet-img/orange.jpg';

const Booking = () => {

  const { loggedInUser } = useAuthContext();
  const { submitBooking } = useBooking();
  
  const [userData, setUserData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedJet, setSelectedJet] = useState('');
  const [selectedPackage, setSelectedPackage] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [bookedSlots, setBookedSlots] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);
  const [openDropdown, setOpenDropdown] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [createAccount, setCreateAccount] = useState(false);
  // const [notice, setNotice] = useState('');

  const fetchUserData = async () => {
    const response = await fetch('https://api.epicjet.com.my/user/id/'+loggedInUser.user._id, {
      method: 'GET',
			headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loggedInUser.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
      console.log(json);
			setUserData(json);
      setIsLoggedIn(true);
		} 
    // else {
    //   window.location.href = "/login";
    // }
	};

  useEffect(() => {
    console.log("Booking page:", loggedInUser);
    if (loggedInUser) {
      fetchUserData();
    }
  }, [loggedInUser]);

  const jets = [
    'Red Epic Car Jet (2 Seater)',
    'Pink Epic Car Jet (4 Seater)',
    'Orange Epic Car Jet (4 Seater)'
  ];

  const jetImages = {
    Red: RedJet,
    Pink: PinkJet,
    Orange: OrangeJet
  };

  const locations = ['Awana @ Resort World'];

  const packages = [
    { name: 'Single Tour', value: 'single_tour', disabled: false },
    { name: 'Island Hopping Tour (Coming Soon)', value: 'island_hopping', disabled: true },
  ];

  const timeSlots = [
    '08:30 - 09:00', '09:00 - 09:30', '09:30 - 10:00', '10:00 - 10:30',
    '10:30 - 11:00', '11:00 - 11:30', '11:30 - 12:00', '12:00 - 12:30',
    '12:30 - 13:00', '13:00 - 13:30', '13:30 - 14:00', '14:00 - 14:30',
    '14:30 - 15:00', '15:00 - 15:30', '15:30 - 16:00', '16:00 - 16:30',
    '16:30 - 17:00', '17:00 - 17:30', '17:30 - 18:00', '18:00 - 18:30',
    '18:30 - 19:00'
  ];

  const getJetImage = (jet) => {
    if (jet.includes('Red')) return jetImages.Red;
    if (jet.includes('Pink')) return jetImages.Pink;
    if (jet.includes('Orange')) return jetImages.Orange;
    return null;
  };
  
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);

    const today = new Date().toISOString().split('T')[0];
    if (newDate === today && allTodaySlotsPassed()) {
      alert('All of today\'s time slots have passed. Please select the next available day.');
      clearSelections();
    }
  };

  const clearSelections = () => {
    setSelectedDate('');
    setSelectedTime('');
    setSelectedJet('');
    setSelectedPackage('');
    setSelectedLocation('');
  };

  const handleTimeSelect = (time, isUnavailable) => {
    if (!isUnavailable) {
      setSelectedTime(time);
    }
  };

  const handleJetSelect = (jet, isUnavailable) => {
    if (!isUnavailable) {
      setSelectedJet(jet);
    }
  };

  const handlePackageSelect = (pkg, isUnavailable) => {
    if (!isUnavailable) {
      setSelectedPackage(pkg);
    }
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  const isFullyBookedTimeslot = (time) => {
    const bookedForTime = bookedSlots.filter(booking => booking.date === selectedDate && booking.time === time);
    return bookedForTime.length === jets.length;
  };

  const isJetUnavailableForTimeslot = (jet) => {
    return bookedSlots.some(booking => booking.date === selectedDate && booking.time === selectedTime && booking.jet === jet);
  };

  const allTodaySlotsPassed = () => {
    const today = new Date().toISOString().split('T')[0];  
    return timeSlots.every(slot => {
      const [slotTime] = slot.split(' - ');
      const slotDateTime = new Date(`${today}T${slotTime}`);
      return slotDateTime < new Date();
    });
  };

  const isPastTimeSlot = (slot) => {
    const [slotTime] = slot.split(' - ');
    const slotDateTime = new Date(`${selectedDate}T${slotTime}`);
    return slotDateTime < new Date();
  };

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    document.getElementById('date').setAttribute('min', today);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const selectedDay = new Date(selectedDate).getDay();
      let availableSlots = timeSlots.map(slot => {
        const isPast = isPastTimeSlot(slot);
        return {
          time: slot,
          disabled: isPast
        };
      });
  
      if (selectedDay === 0) {
        alert("We are closed on Sundays. Please select another date.");
        setSelectedDate('');
        return;
      }
      setTimeOptions(availableSlots);
    }
  }, [selectedDate]);
  
  useEffect(() => {
    if (selectedTime) {
      const availableJets = jets.filter(jet => !isJetUnavailableForTimeslot(jet));
      if (availableJets.length === 0) {
        alert("No jets available for this timeslot.");
        setSelectedTime('');
      }
    }
  }, [selectedTime, bookedSlots]);

  const sendTelegramMessage = async (message) => {
    const botToken = '7927542789:AAHz36YIrcY_S-YHovw__wNW2-b8j5eoBug';
    const chatId = '-4511099748';

    try {
      await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        chat_id: chatId,
        text: message,
      });
    } catch (error) {
      console.error('Error sending message to Telegram:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoggedIn && (!name || !email || !phone || !selectedPackage || !selectedDate || !selectedTime || !selectedJet || !selectedLocation)) {
      alert("Please complete the form to proceed with the booking.");
      return;
    }

    const bookingData = {
      date: selectedDate,
      time: selectedTime,
      jet: selectedJet,
      package: selectedPackage,
      location: selectedLocation,
    };

    if (isLoggedIn) {
      await submitBooking(loggedInUser.token, userData._id, userData.email, userData.phone, selectedDate, selectedTime, selectedJet, selectedPackage, selectedLocation, "673d99c1553a7b4eb8bbe298");
    } else {
      console.log("Guest Booking")
      await submitBooking("guest", "guest", email, phone, selectedDate, selectedTime, selectedJet, selectedPackage, selectedLocation, "673d99c1553a7b4eb8bbe298");
    }
    
    const newBooking = { 
      name: isLoggedIn && userData.name != "" ? userData.name : name,
      email: isLoggedIn ? userData.email : email,
      phone: isLoggedIn && userData.phone != "" ? userData.phone : phone,
      date: selectedDate, 
      time: selectedTime, 
      jet: selectedJet,
      package: selectedPackage,
      location: selectedLocation
    };
    setBookedSlots([...bookedSlots, newBooking]);

    const message = 
    `New booking:
    Customer Name: ${isLoggedIn && userData.name != "" ? userData.name : name}
    Customer Contact: ${isLoggedIn && userData.phone != "" ? userData.phone : phone}
    Customer Email: ${isLoggedIn ? userData.email : email}
    Date: ${selectedDate}
    Time: ${selectedTime}
    Package: ${selectedPackage}
    Jet Color: ${selectedJet}
    Location: ${selectedLocation}`;

    await sendTelegramMessage(message);

    alert(`Booking confirmed for ${selectedPackage} package, ${selectedTime} on ${selectedDate} with ${selectedJet} at ${selectedLocation}.`);
    setSelectedDate('');
    setSelectedTime('');
    setSelectedJet('');
    setSelectedPackage('');
    setSelectedLocation('');
  };

  return (
    <>
      <section className="section-preset extra-mt">
        <div className="container">
          <div className="row">
            <div className="col-12 p-1">
              <h4 className="text-center my-3"><strong>Book an Epic Trip now!</strong></h4>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-8 p-1">
              <div className="profile-data-box">
                <form className="booking" onSubmit={handleSubmit}>
                  { !isLoggedIn && (
                    <div className="user-details mt-3 mb-4">
                      <p className="m-0 text-center" style={{ fontSize:'14px' }}>Have an account? <a href="/login">Login here</a></p>
                      <p className="m-0 text-center" style={{ fontSize:'14px' }}>or</p>
                      <p className="m-0 text-center" style={{ fontSize:'14px' }}>Please fill in the details below.</p>
                      <div className="user-details-field">
                        <label>
                          <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" disabled={isLoggedIn} />
                        </label>
                        <label>
                          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" disabled={isLoggedIn} />
                        </label>
                        <label>
                          <input type="tel" placeholder="Phone number" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" disabled={isLoggedIn} />
                        </label>
                      </div>
                    </div>
                    )
                  }
                
                  <div className="select-package">
                    <CustomSelect
                      options={packages}
                      selectedOption={selectedPackage}
                      onSelect={handlePackageSelect}
                      isDisabled={false}
                      isUnavailable={(pkg) => pkg.disabled}
                      placeholder="Select a package"
                      openDropdown={openDropdown}
                      setOpenDropdown={setOpenDropdown}
                      dropdownId="package"
                    />
                  </div>
                  <input
                    type="date"
                    id="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    min={new Date().toISOString().split('T')[0]}
                    className="form-control"
                    disabled={!selectedPackage}
                  />
                  <div className="select-time">
                  <CustomSelect
                    options={timeOptions}  // This now includes past slots with disabled status
                    selectedOption={selectedTime}
                    onSelect={handleTimeSelect}
                    isDisabled={!selectedDate}
                    isUnavailable={(option) => option.disabled || isFullyBookedTimeslot(option.time)}
                    showFullyBookedLabel={true}
                    placeholder="Select a timeslot"
                    openDropdown={openDropdown}
                    setOpenDropdown={setOpenDropdown}
                    dropdownId="timeslot"
                  />
                  </div>
                  <div className="select-jet">
                    <CustomSelect
                      options={jets}
                      selectedOption={selectedJet}
                      onSelect={handleJetSelect}
                      isDisabled={!selectedDate || !selectedTime}
                      isUnavailable={isJetUnavailableForTimeslot}
                      showFullyBookedLabel={false}
                      placeholder="Select a jet"
                      openDropdown={openDropdown}
                      setOpenDropdown={setOpenDropdown}
                      dropdownId="jet"
                    />
                  </div>
                  <div className="select-location">
                    <CustomSelect
                      options={locations}
                      selectedOption={selectedLocation}
                      onSelect={handleLocationSelect}
                      isDisabled={false} 
                      isUnavailable={() => false}
                      placeholder="Select a pick up location"
                      openDropdown={openDropdown}
                      setOpenDropdown={setOpenDropdown}
                      dropdownId="location"
                    />
                  </div>
                </form>
              </div>
            </div>

            <div className="col-12 col-lg-4 p-1">
              <div className="booking-info">
                <h5 className="mb-4"><strong>Booking Information</strong></h5>
                {selectedPackage ? (
                  <div>
                    <p className="mb-0"><strong>Package:</strong> {selectedPackage}</p>
                    <p className="mb-0"><strong>Date:</strong> {selectedDate || 'Not selected'}</p>
                    <p className="mb-0"><strong>Time:</strong> {selectedTime || 'Not selected'}</p>
                    <p className="mb-0"><strong>Jet:</strong> {selectedJet || 'Not selected'}</p>
                    {selectedJet && (
                      <div className="jet-image-container">
                        <img
                          src={getJetImage(selectedJet)}
                          alt={`${selectedJet} Image`}
                          className="jet-image w-75 my-4"
                        />
                      </div>
                    )}
                    <p><strong>Location:</strong> {selectedLocation || 'Not selected'}</p>
                    <button disabled={selectedLocation == ""} className="btn setting-button mt-2">
                      <p className="mb-0"><strong>Book Now</strong></p>
                    </button>
                  </div>
                ) : (
                  <p>Please select your preferred package</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const CustomSelect = ({
  options,
  selectedOption,
  onSelect,
  isDisabled,
  placeholder,
  isUnavailable,
  showFullyBookedLabel,
  openDropdown,
  setOpenDropdown,
  dropdownId
}) => {
  const isOpen = openDropdown === dropdownId;

  const handleSelect = (option) => {
    const unavailable = isUnavailable(option);
    onSelect(option.time || option.name || option, unavailable);
    if (!unavailable) {
      setOpenDropdown('');
    }
  };

  return (
    <div className="custom-select-container">
      <div
        className={`custom-select-header ${isDisabled ? 'disabled' : ''}`}
        onClick={() => {
          if (!isDisabled) {
            setOpenDropdown(isOpen ? '' : dropdownId);
          }
        }}
      >
        {selectedOption || placeholder}
        <span className="arrow">{isOpen && !isDisabled ? '▲' : '▼'}</span>
      </div>
      {isOpen && !isDisabled && (
        <ul className="custom-select-dropdown">
          {options.map((option) => {
            const unavailable = isUnavailable(option);
            return (
              <li
                key={option.time || option.name || option}
                className={`custom-select-option ${unavailable ? 'unavailable' : ''}`}
                onClick={() => handleSelect(option)}
              >
                <span style={{ textDecoration: unavailable ? 'line-through' : 'none', marginRight: '5px' }}>
                  {option.time || option.name || option}
                </span>
                <span>
                  {unavailable && showFullyBookedLabel ? '(not available)' : ''}
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};


export default Booking;