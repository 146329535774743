import { useState } from 'react';

export const useBooking = () => {

    // const [submittedRecharge, setSubmittedRecharge] = useState(null);
    // const [selectedRecharge, setSelectedRecharge] = useState(null);

    const submitBooking = async (userToken, userID, userEmail, userPhone, date, time, jet, selectedTour, location, merchant_id) => {

        const response = await fetch('https://api.epicjet.com.my/booking/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            body: JSON.stringify({
                user_id: userID,
                user_email: userEmail,
                user_phone: userPhone,
                date,
                time,
                jet,
                package: selectedTour,
                location,
                price: 1200,
                merchant_id
            })
        })
        const json = await response.json();
        if (!response.ok) {
            console.log(json);
            console.log("Failed.");
        }
        if (response.ok) {
            console.log("Success.", json);
            window.location.href = "/profile#bookings";
        }
    }

    return { submitBooking };
}