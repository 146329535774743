import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './login.css';

import { useLogin } from '../../hooks/useLogin';

const Login = () => {

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const {login, error, isLoading} = useLogin();

	const handleSubmit = async (e) => {
		e.preventDefault();
		await login(username, password);
		// console.log("Login");
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="section-preset extra-mt">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
					<h4 className="text-center my-3"><strong>User Login</strong></h4>
					</div>
				</div>
			</div>

			<div className="container form-page-padding">
				<div className="row form-bg rounded px-3 py-5 mt-3 mb-5">
					<div className="col-12">
						{/* <img className="form-logo mb-3" src={LogoText} alt="logo"></img> */}
						<form className="login w-100" onSubmit={handleSubmit}>
							<div className="mb-2">
								<label htmlFor="username">
									<strong className="">E-mail</strong>
								</label>
								<input
									type="text"
									placeholder='username'
									autoComplete='off'
									name='username'
									className='text-lowercase'
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
							<div className="mt-3 mb-2">
								<label htmlFor="password">
									<strong className="">Password</strong>
								</label>
								<input
									type="password"
									placeholder='password'
									name='password'
									className=''
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<button disabled={isLoading} type="submit" className="btn action-button py-2 mt-3 w-100">
								<p className="text-white mb-0"><strong>Login</strong></p>
							</button>
							{error && <div className="error">{error}</div>}
						</form>
						<p className="mt-5 mb-0">Don't have an account? <a href="/register">Register Now</a></p>
						{/* <NavLink to="/register" className="btn secondary-button py-2 w-100">
							<p className="mb-0"><strong>Register</strong></p>
						</NavLink> */}
					</div>
				</div>
			</div>
		</section>
	);

};

export default Login;