import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const PrivacyPolicy = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="section-preset extra-mt">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className="text-center my-3"><strong>Privacy Policy</strong></h4>
					</div>
					<div className="col-12 mt-3 p-1">

						<p className="my-3" style={{fontSize:"14px"}}>
							At Epic Jet Holidays Sdn Bhd, accessible from https://epicjet.com.my/, your privacy is one of our top priorities. This Privacy Policy outlines the types of personal information we collect, how we use it, and the measures we take to protect your data. By using our website, you consent to the practices described in this policy.
						</p>
						<h5 className="mt-5 mb-3"><strong>Information We Collect:</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							When you visit or interact with our website, we may collect the following types of information:
						</p>
						<p className="my-3" style={{fontSize:"14px"}}>
							<strong>Personal Information:</strong>
							<br></br>
							- Name
							<br></br>
							- Email address
							<br></br>
							- Phone number
							<br></br>
							- Travel itinerary details, including booking time and location
						</p>
						<p className="my-3" style={{fontSize:"14px"}}>
							<strong>Non-Personal Information:</strong>
							<br></br>
							- IP address
							<br></br>
							- Browser type and version
							<br></br>
							- Operating system
							<br></br>
							- Time and date of access
						</p>

						<h5 className="mt-5 mb-3"><strong>How We Use Your Information?</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							The information we collect is used for the following purposes:
							<br></br>
							1. <strong>To Provide Services:</strong> To process and confirm jet rental bookings and related services.
							<br></br>
							2. <strong>Customer Support:</strong> To respond to inquiries, provide support, and handle complaints.
							<br></br>
							3. <strong>Personalization:</strong> To customize your browsing experience and present relevant offers.
							<br></br>
							4. <strong>Marketing:</strong> To send promotional materials or updates about our services (you can opt out at any time).
							<br></br>
							5. <strong>Security and Compliance:</strong> To prevent fraud and ensure compliance with legal requirements.
						</p>

						<h5 className="mt-5 mb-3"><strong>How We Share Your Information</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							We do not sell your personal information to third parties. However, we may share your data with:
							<br></br>
							1. <strong>Service Providers:</strong> Third-party vendors who assist with payment processing, IT services, or marketing.
							<br></br>
							2. <strong>Legal Authorities:</strong> If required to comply with legal obligations or to protect our rights and safety.
							<br></br>
							3. <strong>Partners:</strong> Selected partners involved in providing the services you request.
						</p>

						<h5 className="mt-5 mb-3"><strong>How We Share Your Information</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, loss, or misuse. Despite our best efforts, no method of data transmission or storage is 100% secure.
						</p>

						<h5 className="mt-5 mb-3"><strong>International Users</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							As a Malaysia-based website, our data handling practices comply with local regulations. If you access our website from outside Malaysia, note that your data may be transferred to and processed in Malaysia, where data protection laws may differ.
						</p>

						<h5 className="mt-5 mb-3"><strong>Changes to This Policy</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page with the "Last Updated" date.
						</p>

						<h5 className="mt-5 mb-3"><strong>Contact Us</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us:
						</p>
						<p className="my-3" style={{fontSize:"14px"}}>
							<strong>Epic Jet Holidays Sdn Bhd (1559847­P)</strong>
							<br></br>
							<strong>Email: </strong>contact@epicjet.com.my
							<br></br>
							<strong>Phone: </strong>+6012-7733649
						</p>

						<p className="my-3" style={{fontSize:"14px"}}>
							<strong>Last Updated:</strong> 26th November 2024
						</p>
					</div>
				</div>
			</div>
		</section>
	);

};

export default PrivacyPolicy;