import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const TermsAndConditions = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="section-preset extra-mt">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className="text-center my-3"><strong>Terms And Conditions</strong></h4>
					</div>
					<div className="col-12 mt-3 p-1">
						<p className="my-3" style={{fontSize:"14px"}}>
							Welcome to Epic Jet Holidays Sdn Bhd! These Terms and Conditions govern your use of our website, https://epicjet.com.my/ (the "Site"), and the services we offer. By accessing or using our Site, you agree to be bound by these Terms and Conditions. If you do not agree, please do not use our Site.
						</p>

						<h5 className="mt-5 mb-3"><strong>Use of the Site</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							1. <strong>Eligibility:</strong> You must be at least 18 years old and capable of forming legally binding contracts to use our services.
							<br></br>
							2. <strong>Accuracy:</strong> You agree to provide accurate and complete information when booking services or creating an account.
							<br></br>
							3. <strong>Prohibited Activities:</strong> You agree not to:
							<br></br>
							- Use the Site for unlawful purposes.
							<br></br>
							- Attempt to interfere with the Site’s operation or security.
							<br></br>
							- Engage in fraudulent or unauthorized transactions.
						</p>


						<h5 className="mt-5 mb-3"><strong>Jet Rental Services</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							1. <strong>Booking:</strong> All bookings are subject to availability and confirmation. You will receive a confirmation email upon successful booking.
							<br></br>
							2. <strong>Payment:</strong>  Full payment must be made at the time of booking unless otherwise agreed. Payment methods accepted are detailed on the Site.
							<br></br>
							3. <strong>Cancellations and Refunds:</strong>
							<br></br>
							- Cancellations by customers may be subject to fees as detailed in our cancellation policy.
							<br></br>
							- Refunds, if applicable, will be processed within 14 business days.
							<br></br>
							4. <strong>Service Modifications:</strong> Epic Jet Holidays Sdn Bhd reserves the right to modify or cancel bookings due to unforeseen circumstances, including weather, mechanical issues, or safety concerns.
						</p>


						{/* <h5 className="mt-5 mb-3"><strong>Intellectual Property</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							All content on the Site, including text, images, graphics, logos, and software, is the property of Epic Jet Holidays Sdn Bhd or its licensors. Unauthorized use of the content is prohibited.
						</p> */}


						<h5 className="mt-5 mb-3"><strong>Limitation of Liability</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							1. <strong>Service Use:</strong> Epic Jet Holidays Sdn Bhd is not liable for any direct, indirect, incidental, or consequential damages arising from your use of the Site or services.
							<br></br>
							2. <strong>Third-Party Services:</strong>  We may link to third-party websites for additional services. We are not responsible for the content, terms, or privacy practices of such third parties.
						</p>

						<h5 className="mt-5 mb-3"><strong>Indemnification</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							You agree to indemnify and hold Epic Jet Holidays Sdn Bhd harmless from any claims, damages, or losses arising from your breach of these Terms and Conditions or misuse of the Site.
						</p>

						<h5 className="mt-5 mb-3"><strong>Privacy</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							Your use of our Site is also governed by our [Privacy Policy](https://epicjet.com.my/privacy). Please review it to understand our data handling practices.
						</p>

						<h5 className="mt-5 mb-3"><strong>Governing Law</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							These Terms and Conditions are governed by the laws of Malaysia. Any disputes will be resolved in the courts of Malaysia.
						</p>

						<h5 className="mt-5 mb-3"><strong>Changes to the Terms</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							Epic Jet Holidays Sdn Bhd reserves the right to update or modify these Terms and Conditions at any time. Changes will be effective upon posting on the Site. Continued use of the Site signifies your acceptance of the updated Terms.
						</p>

						<h5 className="mt-5 mb-3"><strong>Contact Us</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							If you have questions or need assistance with the refund process, please contact us:
						</p>
						<p className="my-3" style={{fontSize:"14px"}}>
							<strong>Epic Jet Holidays Sdn Bhd (1559847­P)</strong>
							<br></br>
							<strong>Email: </strong>contact@epicjet.com.my
							<br></br>
							<strong>Phone: </strong>+6012-7733649
						</p>

						<p className="my-3" style={{fontSize:"14px"}}>
							<strong>Last Updated:</strong> 26th November 2024
						</p>
					</div>
				</div>
			</div>
		</section>
	);

};

export default TermsAndConditions;