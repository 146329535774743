import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import './register.css';

// Hooks
import { useSignup } from '../../hooks/useSignup';

const Register = () => {

	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const { signup, isLoading, error } = useSignup();

	const handleSubmit = async (e) => {
        e.preventDefault();
		await signup(name, phone, email, password);
    };

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="section-preset extra-mt">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className="text-center my-3"><strong>Register Account</strong></h4>
					</div>
				</div>
			</div>
			<div className="container form-page-padding">
				<div className="row form-bg rounded px-3 py-5 mt-3 mb-5">
					<div className="col-12 register">
						<form className="w-100" onSubmit={handleSubmit}>
							<div className="mb-2">
								<label htmlFor="name">
									<strong className="">Name</strong>
								</label>
								<input
									required
									type="text"
									placeholder='Name'
									autoComplete='off'
									name='name'
									className=''
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="phone">
									<strong className="">Phone</strong>
								</label>
								<PhoneInput
									inputProps={{
										name: 'phone',
										placeholder: 'Enter Phone Number',
										required: true
									}}
									country={"my"}
									enableSearch={false}
									value={phone}
									onChange={(value) => setPhone(value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="email">
									<strong className="">Email</strong>
								</label>
								<input
									type="text"
									placeholder='email'
									autoComplete='off'
									name='email'
									className=''
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="mt-3 mb-2">
								<label htmlFor="password">
									<strong className="">Password</strong>
								</label>
								<input
									type="password"
									placeholder='password'
									name='password'
									className=''
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<button disabled={isLoading} type="submit" className="btn action-button py-2 mt-3 w-100">
								<p className="text-white mb-0"><strong>Register</strong></p>
							</button>
							{error && <div className="error">{error}</div>}
						</form>
						<p className="mt-5 mb-0">Already registered? <a href="/login">Login  Now</a></p>
						{/* <NavLink to="/login" className="btn secondary-button w-100">
							<p className="mb-0"><strong>Login</strong></p>
						</NavLink> */}
					</div>
				</div>
			</div>
		</section>
	);

};

export default Register;