import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const RefundPolicy = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="section-preset extra-mt">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className="text-center my-3"><strong>Refund Policy</strong></h4>
					</div>
					<div className="col-12 mt-3 p-1">
						<p className="my-3" style={{fontSize:"14px"}}>
							At Epic Jet Holidays Sdn Bhd, we strive to provide the best service experience to our customers. This Refund Policy outlines the conditions under which refunds may be issued and the process to request a refund. By using our services, you agree to the terms outlined in this policy.
						</p>
						<h5 className="mt-5 mb-3"><strong>Refund Eligibility</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							Refunds are only allowed under the following circumstances:
						</p>
						<p className="my-3" style={{fontSize:"14px"}}>
							<strong>Double Payment or Double Charge:</strong>
							<br></br>
							- If you have been charged twice for the same booking or service, you are eligible for a refund for the duplicate payment.
						</p>
						<p className="my-3" style={{fontSize:"14px"}}>
							<strong>Case-to-Case Basis:</strong>
							<br></br>
							- Refunds outside of double payment scenarios will be evaluated on a case-to-case basis, considering the circumstances and supporting documentation.
						</p>

						<h5 className="mt-5 mb-3"><strong>Non-Refundable Cases</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							Refunds will not be issued under the following circumstances:
							<br></br>
							- Cancellations by the customer outside the cancellation policy terms.
							<br></br>
							- Failure to use the booked service without prior notification.
							<br></br>
							- Issues arising from incorrect information provided during booking.
						</p>

						<h5 className="mt-5 mb-3"><strong>Refund Process</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							To request a refund, please follow these steps:
							<br></br>
							1. <strong>Contact Us:</strong> Submit a refund request via email at contact@epicjet.com.my or call us at +6012-7733649. Include the following details:
							<br></br>
							- Full name
							<br></br>
							- Booking reference number
							<br></br>
							- Date of payment
							<br></br>
							- Proof of double payment (e.g., bank statement, receipt)
							<br></br>
							2. <strong>Evaluation:</strong>
							<br></br>
							- Our team will review your request and any supporting documentation.
							<br></br>
							- Additional information may be requested to validate your claim.
							<br></br>
							3. <strong>Decisions</strong>
							<br></br>
							- Refund eligibility will be communicated to you within 7 business days.
							<br></br>
							4. <strong>Processing</strong>
							<br></br>
							- Approved refunds will be processed within 14 business days from the date of approval.
						</p>

						<h5 className="mt-5 mb-3"><strong>Contact Us</strong></h5>
						<p className="my-3" style={{fontSize:"14px"}}>
							If you have questions or need assistance with the refund process, please contact us:
						</p>
						<p className="my-3" style={{fontSize:"14px"}}>
							<strong>Epic Jet Holidays Sdn Bhd (1559847­P)</strong>
							<br></br>
							<strong>Email: </strong>contact@epicjet.com.my
							<br></br>
							<strong>Phone: </strong>+6012-7733649
						</p>

						<p className="my-3" style={{fontSize:"14px"}}>
							<strong>Last Updated:</strong> 26th November 2024
						</p>
					</div>
				</div>
			</div>
		</section>
	);

};

export default RefundPolicy;