import { useState } from 'react';
import { useAuthContext } from './useAuthContext';

export const useSignup = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const { dispatch } = useAuthContext();

    const signup = async (name, phone, email, password) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.epicjet.com.my/user/register', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
                name,
                phone,
                email,
                password
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
            setIsLoading(false);
            // save the user to local storage
            localStorage.setItem('epicjet_login', JSON.stringify(json));

            // update the auth context
            dispatch({type: 'LOGIN', payload: json});

            setIsLoading(false);

            window.location.href = "/";
		}
    }

    return { signup, isLoading, error };
}