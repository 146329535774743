import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import sha256 from 'js-sha256';

import './profile.css';

import { useAuthContext } from '../../hooks/useAuthContext';

import { useLogout } from '../../hooks/useLogout';

const hashSHA256 = (data) => {
  return sha256(data);
};

const Profile = () => {

	const paymentID = 'PTXNm1239874'

	// Generate query link to check if transaction exist and successful
	// useEffect(() => {
  //   // Test if the SHA256 hashing is working properly
  //   const testHash = hashSHA256('sit12345sit'+paymentID+'1200.00MYR');
	// 	console.log("Txn Status check: https://pay.e-ghl.com/IPGSG/Payment.aspx?TransactionType=QUERY&PymtMethod=ANY&ServiceID=sit&PaymentID=PTXNm1239874&OrderNumber="+paymentID+"&PaymentDesc=PayBooking&MerchantReturnURL=https://epicjet.com.my/profile&Amount=1200.00&CurrencyCode=MYR&HashValue="+testHash);
  // }, []);

	const constructPayLink = async () => {
		// Generate SHA256
		const generatedValue = hashSHA256('sit12345sit'+paymentID+'https://epicjet.com.my/profile1200.00MYR161.142.139.108')

		const finalPayLink = 'https://pay.e-ghl.com/IPGSG/Payment.aspx?TransactionType=SALE&PymtMethod=ANY&ServiceID=sit&PaymentID='+paymentID+'&OrderNumber='+paymentID+'&PaymentDesc=PayBooking&MerchantReturnURL=https://epicjet.com.my/profile&Amount=1200.00&CurrencyCode=MYR&HashValue='+generatedValue+'&CustIP=161.142.139.108&CustName='+userData.name+'&CustEmail='+userData.email+'&CustPhone='+userData.phone
		
		window.open(finalPayLink, "_blank");
	};

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.epicjet.com.my/user/id/'+loggedInUser.user._id, {
			method: 'GET',
			headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${loggedInUser.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			console.log(json);
			setUserData(json);
		} else {
			window.location.href = "/login";
		}
	};

	const [bookingData, setBookingData] = useState(null);
	const fetchUserBookingData = async () => {
		const response = await fetch('https://api.epicjet.com.my/booking/user/'+loggedInUser.user._id, {
			method: 'GET',
			headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${loggedInUser.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			console.log("User booking records: ", json);
			setBookingData(json);
		}
	};

	const { logout } = useLogout();
	const handleLogout = () => {
	  logout();
	  window.location.href= "/";
	}

	useEffect(() => {
		if (loggedInUser) {
			fetchUserData();
			fetchUserBookingData();
		}
	}, [loggedInUser]);


	return (
		<section className="section-preset extra-mt">
			<div className="container">

				<div className="row">
					<div className="col-12 p-1">
						<h4 className="text-center my-3"><strong>My Profile</strong></h4>
					</div>
				</div>

				{userData != null ? (
					<div className="row">
						<div className="col-12 p-1">
							<div className="profile-data-box">
								<p className="mb-0"><strong>E-mail:</strong> {userData && userData.email}</p>
								<p className="mb-0"><strong>Name:</strong> {userData && userData.name == "" ? "Not Set" : userData.name}</p>
								<button className="btn btn-danger mt-3 w-100" onClick={handleLogout}>
									<p className="mb-0">Logout</p>
								</button>
							</div>
						</div>
					</div>
				):(
					<></>
				)}

				<div id="bookings" className="row mt-5">
					<div className="col-12 p-1">
						<h4 className="text-center my-3"><strong>My Bookings</strong></h4>
					</div>
					<div className="col-12 p-1">
						{bookingData && bookingData.length > 0 ? (
							<>
								{bookingData && bookingData.map((booking, index) => (
									<div className="profile-data-box mt-3" key={index}>
										<p className="mb-0">
											<strong>
												Status:
												{booking.status == "Processing" ? <span className="brand-yellow ml-2">Pending Payment</span>: <></>}
												{booking.status == "Paid" ? <span className="profit-green ml-2">Payment Completed</span>: <></>}
											</strong>
										</p>
										<p className="mb-0"><strong>Booking ID: </strong> {booking._id}</p>
										<p className="mb-0"><strong>Payment Amount: </strong> RM {parseFloat(booking.price).toFixed(2)}</p>
										{booking.status == "Processing" ? (
											<button className="btn action-button mt-2" onClick={constructPayLink}><p className="mb-0">Pay Now</p></button>
										) : (
											<></>
										)}
										<hr></hr>
										<p className="mb-0"><strong>Package: </strong> {booking.package}</p>
										<p className="mb-0"><strong>Date: </strong> {booking.date}</p>
										<p className="mb-0"><strong>Time: </strong> {booking.time}</p>
										<p className="mb-0"><strong>Jet Type: </strong> {booking.jet}</p>
										<p className="mb-0"><strong>Location: </strong> {booking.location}</p>
									</div>
								))}
							</>
						):(
							<>
								<div className="profile-data-box mt-2">
									<p className="text-center mb-0">No booking record found for this account. Plan and book a trip now!</p>
								</div>
							</>
						)}

					</div>
				</div>
			</div>

		</section>
	);

};

export default Profile;