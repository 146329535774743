import React from "react";

const ContactUsIcon = () => {
  
  return (
  	<div class="floating-btn">
      <button className="btn contact-btn"
        onClick={() => {
          window.open("https://wa.link/vd7zn3", "_blank");
        }}
      >
        <span className="contact-icon"><i className="fa-brands fa-whatsapp"></i></span>
      </button>
    </div>
  );

};

export default ContactUsIcon;