import React, { useEffect, useState } from 'react';

import HeroBanner from '../components/hero-banner';
import Packages from '../components/packages';
import Footer from '../components/footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import watersportcar1 from "../assets/img/jet-img/watersportcarpackage1.webp";
import watersportcar2 from "../assets/img/jet-img/watersportcar1.webp";
import watersportcar3 from "../assets/img/jet-img/watersportcar2.webp";
import watersportcar4 from "../assets/img/jet-img/watersportcar3.webp";
import watersportcar5 from "../assets/img/jet-img/watersportcar4.webp";
import watersportcar6 from "../assets/img/jet-img/watersportcar5.webp";
import watersportcar7 from "../assets/img/jet-img/watersportcar6.webp";
import watersportcar8 from "../assets/img/jet-img/watersportcar7.webp";
import watersportcar9 from "../assets/img/jet-img/watersportcar8.webp";

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const Home = () => {

	return (
		<>
      <HeroBanner/>

      <div id='aboutus' className="container py-5 about-us">
        <div className="row">

          <div className='col-6 displayImg'>
            <img src={watersportcar1} alt="Water Sport Car In Langkawi"></img>
          </div>

          <div className='col-6'>
            <h1>EPIC JET</h1>
            <h3 className="mt-3">The First Luxury Water Sport Car In Langkawi</h3>
            <p className='mt-3'>
              Your gateway to aquatic adventures in the Langkawi. Nestled in the heart of this dynamic metropolis, epic jet is a premier destination for enthusiasts seeking to embrace the thrill of water sports.
            </p>
            <p className='mt-3'>
              From jet skis to island hopping, our extensive range of top-notch equipment ensures an unforgettable experience on the azure water of Langkawi.
            </p>
            <p className='mt-3'>
              Dive into excitement with epic jet, where every wave tells a tale of exhilaration and endless possibilities.
            </p>
          </div>

          <div className='jet-gallery mt-5'>
            <Swiper
                loop
                grabCursor={true}
                slidesPerView={4}
                spaceBetween={10}
                speed={1000}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1.5,
                    spaceBetween : 10
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween : 10
                  },
                }}
              >
                <SwiperSlide><img src={watersportcar2} alt="Water Sport Car In Langkawi"></img></SwiperSlide>
                <SwiperSlide><img src={watersportcar4} alt="Water Sport Car In Langkawi"></img></SwiperSlide>
                <SwiperSlide><img src={watersportcar3} alt="Water Sport Car In Langkawi"></img></SwiperSlide>
                <SwiperSlide><img src={watersportcar5} alt="Water Sport Car In Langkawi"></img></SwiperSlide>
                <SwiperSlide><img src={watersportcar6} alt="Water Sport Car In Langkawi"></img></SwiperSlide>
                <SwiperSlide><img src={watersportcar7} alt="Water Sport Car In Langkawi"></img></SwiperSlide>
                <SwiperSlide><img src={watersportcar8} alt="Water Sport Car In Langkawi"></img></SwiperSlide>
                <SwiperSlide><img src={watersportcar9} alt="Water Sport Car In Langkawi"></img></SwiperSlide>
              </Swiper>
          </div>
          
        </div>
      </div>

      <Packages/>


		</>
	);

};

export default Home;